import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/PostList';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, categories, site } = data
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = site.siteMetadata
    const { name: category, slug } = pageContext
    const title = `${category} category`

    return (
      <Layout className={`page-wrap`}>
        <SEO title={`${category} | ${siteTitle}`} />
        <PostList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categories={categories.edges}
          siteMetadata={site.siteMetadata}
          pathPrefix={`/${site.siteMetadata.blogSlug}/category/${slug}/`}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title,
        siteUrl,
        wordpressUrl,
        blogSlug
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
